/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';


/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";






@font-face {
    font-family: 'PGT Font';
    src: url('../src/fonts/pgt.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }


.header-ios ion-toolbar:last-of-type {
     --border-width: 0 0 0px; 
     --background: none!important;
}


.toolbar-background {
    border-style: none!important;
}

  @font-face {
    font-family: PGT Font;
    font-style: normal;
    font-weight: 400;
    src: url('../src/fonts/default-274a65bae9742377aafx.woff') format("woff");
}

@font-face {
    font-family: PGT Font;
    font-style: normal;
    font-weight: 700;
    src: url('../src/fonts/default-815fcbb4d2c57901701x.woff') format("woff");
}

@font-face {
    font-family: PGT Font;
    font-style: normal;
    font-weight: 300;
    src: url('../src/fonts/default-5a6dd86f272b304a8b8x.woff') format("woff");
}


@font-face {
    font-family: PGT Font;
    font-style: normal;
    font-weight: 900;
    src: url('../src/fonts/default-3e828e80f6e985c352ex.woff') format("woff");
}




html, body {
    font-family: 'PGT Font', sans-serif;

  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'PGT Font', sans-serif;
    font-weight: 700;
   
  }

  p, span {
    font-family: 'PGT Font', sans-serif;
    font-weight: 400;
  
  }

a {
    font-family: 'PGT Font', sans-serif;
    font-weight: 400;

  }


ion-header {
    z-index: 10000000000;
    img.logo_brand {
        height: 42px;
    }


}

.img-chat{
max-height: 90px;
}
.swiper .swiper-scrollbar-drag {
    background: #f6b039;
}

.swiper-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 0px;
    z-index: 45;
    height: 6px;
    width: 98%;
}
  

swiper-container {
    width: 100%;
    height: 100%;
   
    --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #cccccc);
    --swiper-pagination-color: var(--ion-color-primary, #369);
    --swiper-navigation-color: var(--ion-color-primary, #369);
    --swiper-scrollbar-drag-color: var(--ion-color-primary, #369);
    
  }

.modal-fullscreen {
    .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}


.errorToast {
    --background: whitesmoke;
    font-size:20px;
    color:black;    
    font-weight: 800;

     }

.native-input[disabled].sc-ion-input-md {
    opacity: unset;
    
 }
 
 .native-input[disabled].sc-ion-input-ios {
    opacity: unset;
}


h1{font-size: 24px;}
h2{font-size: 21px;}
h3{font-size: 18px;}
h4{font-size: 15px;}

ion-title{
white-space: normal;
font-size:1.2rem;

}

.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h {

    white-space: pre-wrap;
   
}

.item.sc-ion-label-md-h, .item .sc-ion-label-md-h {

    white-space: pre-wrap;
   
}




.country-flag {
    display: inline-block;
    width: 20px;
  }

  .country-flag2 {
    display: inline-block;
    width: 32px;
  }
  
ion-toast.toast-app{
    --background: #231f20;
   
        --color: #ffffff;
    }


ion-loading.custom-loading {
    .loading-wrapper {
        background: transparent;
        box-shadow: none;
        --color: #f6b034 !important;
      }
      color: #f6b034 !important;
    }

    .alert-radio-icon.sc-ion-alert-md {
        left: 24px;
        top: 0;
        border-radius: 50%;
        display: block;
        position: relative;
        width: 17px;
        height: 17px;
        border-width: 2px;
        border-style: solid;
        border-color: var(--ion-color-step-550,#737373);

    }

    .alert-checkbox-icon.sc-ion-alert-md {
        left: 26px;
        top: 0;
        border-radius: 2px;
        position: relative;
        width: 16px;
        height: 16px;
        border-width: 2px;
        border-style: solid;
        border-color: var(--ion-color-step-550,#737373);
        contain: strict;
    }

    .alert-radio-inner.sc-ion-alert-md {
        width: 7px;
        height: 7px;
    }
    

    .alert-radio-label.sc-ion-alert-ios {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: 13px;
        padding-inline-start: 13px;
        -webkit-padding-end: 13px;
        padding-inline-end: 13px;
        font-size: 11.9px;
    }

    .alert-radio-label.sc-ion-alert-md {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: 13px;
        padding-inline-start: 30px;
        -webkit-padding-end: 13px;
        padding-inline-end: 13px;
        font-size: 11.9px;
    }
    .alert-checkbox-label.sc-ion-alert-ios {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: 13px;
        padding-inline-start: 13px;
        -webkit-padding-end: 13px;
        padding-inline-end: 13px;
        font-size: 11.9px;
    }
    .alert-checkbox-label.sc-ion-alert-md {
        padding-left: unset;
        padding-right: unset;
        -webkit-padding-start: 13px;
        padding-inline-start: 35px;
        -webkit-padding-end: 13px;
        padding-inline-end: 13px;
        font-size: 11.9px;
    }


    .item.sc-ion-label-md-h, .item .sc-ion-label-md-h{
     white-space: normal !important;
}


 .sc-ion-label-md-h{
     white-space: normal !important;
}




.txt-primary {
    color: var(--ion-color-primary);
}

.txt-secondary {
    color: var(--ion-color-secondary);
}

.txt-tertiary {
    color: var(--ion-color-tertiary);
}

.txt-success {
    color: var(--ion-color-success);
}

.txt-warning {
    color: var(--ion-color-warning);
}

.txt-danger {
    color: var(--ion-color-danger);
}

.txt-light {
    color: #fff !important;
}

.txt-medium {
    color: var(--ion-color-medium);
}

.txt-dark {
    color: #222 !important;
}

.brand-logo{
    align-content: flex-end;
    height: 60px;
    filter: contrast(0) brightness(200);
}

.brand-logo5{
    align-content: flex-end;
    height: 45px;
}


.fs-nav{
    
    font-size: 3.0vw;
}
.fs-nav-main{
    
    font-size: 18vw;
}
/*text css fontsize*/
    
.fs-5 {
    font-size: 5px !important;
    /*line-height: 11px !important;*/
}

.fs-6 {
    font-size: 6px !important;
    /*line-height: 12px !important;*/
}

.fs-7 {
    font-size: 7px !important;
    /*line-height: 13px !important;*/
}

.fs-8 {
    font-size: 8px !important;
    /*line-height: 14px !important;*/
}

.fs-9 {
    font-size: 9px !important;
    /*line-height: 15px !important;*/
}

.fs-10 {
    font-size: 10px !important;
    line-height: 16px !important;
}

.fs-11 {
    font-size: 11px !important;
    /*line-height: 17px !important;*/
}

.fs-12 {
    font-size: 12px !important;
    /*line-height: 18px !important;*/
}

.fs-13 {
    font-size: 13px !important;
    /*line-height: 19px !important;*/
}

.fs-14 {
    font-size: 14px !important;
    /*line-height: 20px !important;*/
}

.fs-15 {
    font-size: 15px !important;
    /*line-height: 21px !important;*/
}

.fs-16 {
    font-size: 16px !important;
    /*line-height: 22px !important;*/
}

.fs-17 {
    font-size: 17px !important;
    /*line-height: 23px !important;*/
}

.fs-18 {
    font-size: 18px !important;
    /*line-height: 24px !important;*/
}

.fs-19 {
    font-size: 19px !important;
    /*line-height: 25px !important;*/
}

.fs-20 {
    font-size: 20px !important;
    /*line-height: 26px !important;*/
}

.fs-21 {
    font-size: 21px !important;
    /*line-height: 27px !important;*/
}

.fs-22 {
    font-size: 22px !important;
    /*line-height: 28px !important;*/
}

.fs-23 {
    font-size: 23px !important;
    /*line-height: 29px !important;*/
}

.fs-24 {
    font-size: 24px !important;
    /*line-height: 30px !important;*/
}

.fs-25 {
    font-size: 25px !important;
    /*line-height: 31px !important;*/
}

.fs-26 {
    font-size: 26px !important;
    /*line-height: 32px !important;*/
}

.fs-27 {
    font-size: 27px !important;
    /*line-height: 33px !important;*/
}

.fs-28 {
    font-size: 28px !important;
    /*line-height: 34px !important;*/
}

.fs-29 {
    font-size: 29px !important;
    /*line-height: 35px !important;*/
}

.fs-30 {
    font-size: 30px !important;
    /*line-height: 36px !important;*/
}

.fs-32 {
    font-size: 32px !important;
    /*line-height: 38px !important;*/
}

.fs-34 {
    font-size: 34px !important;
    /*line-height: 40px !important;*/
}

.fs-36 {
    font-size: 36px !important;
    /*line-height: 42px !important;*/
}

.fs-38 {
    font-size: 38px !important;
    /*line-height: 44px !important;*/
}

.fs-40 {
    font-size: 40px !important;
    /*line-height: 46px !important;*/
}

.fs-42 {
    font-size: 42px !important;
    /*line-height: 48px !important;*/
}

.fs-44 {
    font-size: 44px !important;
    // line-height: 50px !important;
}

 /*text css fontweight*/
    
 .fw-100 {
    font-weight: 100 !important;
}

.fw-200 {
    font-weight: 200 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-500 {
    font-weight: 500 !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-900 {
    font-weight:bolder !important;
}

.fw-b {
    font-weight: bolder !important;
}


 /*text css style*/
    
 .uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.lowercase {
    text-transform: lowercase;
}

.italic {
    font-style: italic;
}

.line-through {
    text-decoration: line-through;
}

.overline {
    text-decoration: overline;
}

.underline {
    text-decoration: underline;
}

    .ellipsis{
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
        white-space: nowrap;
    }

/*shadow css*/
.shadow-0 {
    box-shadow: none !important;
    --box-shadow: none !important;
}
.shadow-1{
	box-shadow: 0px 2px 5px 0px #666666;
}
.shadow-2{
	box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.1);
}
.shadow-3{
	box-shadow: 0px 10px 25px 0px rgba(0,0,0,0.1);
}
.shadow-4{
	box-shadow: 0px 15px 50px 0px rgba(0,0,0,0.3);
}
.shadow-5{
	box-shadow: 0px 20px 70px 0px rgba(0,0,0,0.5);
}

 /*text css spacing*/
    
 .spacing1 {
    letter-spacing: 1px;
}

.spacing2 {
    letter-spacing: 2px;
}

.spacing3 {
    letter-spacing: 3px;
}

.spacing4 {
    letter-spacing: 4px;
}

.spacing5 {
    letter-spacing: 5px;
}

.spacing6 {
    letter-spacing: 6px;
}

.spacing7 {
    letter-spacing: 7px;
}

.spacing8 {
    letter-spacing: 8px;
}

.spacing9 {
    letter-spacing: 9px;
}

.spacing10 {
    letter-spacing: 10px;}

 .flex-ali-center {
        align-items: center;
    }


    // .searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
    //     background: white;
    // }

    // .searchbar-left-aligned.sc-ion-searchbar-h .searchbar-input.sc-ion-searchbar {
        
    // background: white;
    // }
        

    .brand-logo2{
        align-content: flex-end;
        height: 35px;


       // filter: contrast(0) brightness(200);
    }

    .brand-logo3{
        align-content: flex-end;
        height: 28px;
       // filter: contrast(0) brightness(200);
    }
    .brand-iso{
        align-content: flex-end;
        height: 75px;
       // filter: contrast(0) brightness(200);
    }

    .fixed-outside {
        bottom: 0;
    }


.alert-tappable.alert-radio {
    height: auto;
    contain: content;
}



.square-modal .modal-wrapper {
    --min-width: 40%;
    --min-height: 70%;

  }

  .alert-radio-label.sc-ion-alert-md,
  .alert-radio-label.sc-ion-alert-ios {
      white-space: normal !important;
      font-size: 12px !important;
  }

  .item-inner {

    border-width: none;

}

/*
 * This adds a scroll bar to ionic alerts
 */
 .alert-radio-group::-webkit-scrollbar, .alert-checkbox-group::-webkit-scrollbar {
    width: 0.7em;
    display: block !important;
}

.alert-radio-group::-webkit-scrollbar-track, .alert-checkbox-group::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.alert-radio-group::-webkit-scrollbar-thumb, .alert-checkbox-group::-webkit-scrollbar-thumb {
    background-color: #f6b039;
    outline: 1.2px solid slategrey;
}